<template>
  <el-dialog
    :visible.sync="dialogVisible"
    center
    width="50%"
    title="服务协议"
    top="1vh"
    :close-on-click-modal="false"
    @close="cancel"
  >
    <div style="max-height: 600px; overflow-y: auto">
      <p class="paragraph-content">
        1、在您按照发布页面提示填写信息、阅读并同意本协议并完成全部注册程序后或以其他新能智运允许的方式实际使用新能智运平台服务时，您即受本协议的约束。在注册时，您应当按照法律法规要求，或注册页面的提示，准确填写并及时更新您提供的联系电话等联系方式，以便新能智运或其他会员与您进行有效联系。如您的资料发生变更，您应及时更新您的资料，以使之真实、及时、完整和准确。如有合理理由怀疑您提供的资料错误、不实、过时或不完整的，新能智运有权向您发出询问及/或要求改正的通知，并有权直接做出删除相应资料的处理，直至中止/终止对您提供部分或全部服务。由此导致您在使用新能智运平台服务过程中产生任何损失或增加费用的，应由您完全独自承担。
      </p>
      <p class="paragraph-content">
        2、对于您提供的资料及数据信息，您授予新能智运独家的、全球通用的、永久的、免费的许可使用权利。您同意，新能智运有权(全部或部分地)使用、复制、更正、发布、翻译、分发、执行和展示您的资料数据（包括但不限于注册资料、行为数据及全部展示于新能智运平台的各类信息）或制作其派生作品，并以现在已知或日后开发的任何形式、媒体或技术，将上述信息纳入其它作品内。
        在此，您特别做出授权，允许新能智运依据您提供的信息资料及在新能智运平台上的行为、投诉及侵权状况等进行信用评级，您对新能智运的信用评级体系予以认可并同意新能智运将您在平台存储的全部或部分信息及信用评价结果以有偿或无偿方式提供给第三方进行商业或非商业方式的使用，而无需在该提供行为之前再另行取得您的同意。如您不同意做出前述授权，您应立即停止注册程序或停止使用新能智运平台服务。如您继续使用新能智运平台服务的，即表示您同意做出前述授权。
      </p>
      <p class="paragraph-content">
        3、您应当在使用新能智运平台服务之前认真阅读全部协议内容。如您对协议有任何疑问的，应向新能智运咨询。但无论您事实上是否在使用新能智运平台服务之前认真阅读了本协议内容，只要您使用新能智运平台服务，则本协议即对您产生约束，届时您不应以未阅读本协议的内容或者未获得新能智运对您问询的解答等理由，主张本协议无效，或要求撤销本协议。
      </p>
      <p class="paragraph-content">
        4、本协议内容包括协议正文及所有新能智运已经发布的或将来可能发布的各类规则。所有规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。除另行明确声明外，任何新能智运及其关联公司提供的服务（以下称为新能智运平台服务）均受本协议约束。您承诺接受并遵守本协议的约定。如果您不同意本协议的约定，您应立即停止注册程序或停止使用新能智运平台服务。
      </p>
      <p class="paragraph-content">
        5、新能智运有权根据国家法律法规的更新、产品和服务规则的调整需要不时地制订、修改本协议及/或各类规则，并以网站公示的方式进行公示。如您继续使用新能智运平台服务的，即表示您接受经修订的协议和规则。
      </p>
      <p class="paragraph-content">
        6、您委托运输的货物为一般普通货物，即非超长、超宽、超高或易燃易爆、危险品、禁运品等，且不得夹带国家禁止和限制运输、税务发票、海关通关等手续不全的货物，否则应赔偿平台因此遭受的各种处罚及全部直接或间接损失，包括但不限于行政处罚、许可证被吊销、因此给平台或第三人造成的人身、财产损失等。同时，托运上述货物过程中，非平台原因造成货物毁损、灭失、被政府机关扣留或没收的，由您自行承担，平台不承担任何责任。
        您必须确保在平台发布的货源信息真实有效，提供准确的装货时间、装货地点、发货联系人、联系方式以及货物送达的收货地点、收货联系人、联系方式等有关资料。同时保证对托运的货物具有合法有效的处置权。
        如因非平台单方原因，您无法及时装货、出仓的，由您自行承担相关责任和/或损失、费用。
      </p>
      <p class="paragraph-content">
        7、对于您指定合作的实际承运人，您应自行与实际承运人协商，双方进行货运险、运输责任险、车险等保险投保。此类情况下，如发生运输事故造成损失，由您和实际承运人自行解决，乙方不承担任何责任。
        平台目前暂不提供运费的线上结算服务，您可通过其他方式与实际承运人进行运费结算。
      </p>
      <p class="paragraph-content">
        平台禁运货物包含违禁品、危险品、高风险或无法界定货物价值等类型货物。若委托方未经申报发运这些货物，责任由委托方承担，若对其他客户货物造成损害，平台保留对委托方追偿权利。
        违禁品具体包含
      </p>
      <p class="paragraph-content">
        法律禁止运输的货物：国家法律、行政法规明令禁止运输的货物。
        危及安全的货物：有腐蚀性、有放射性等可能危及运输工具、人身和财产安全的货物。
        易燃易爆物品：凡具有爆炸、易燃等危险性质，在运输、装卸、存储过程中，在一定条件下可能引起爆炸、燃烧，导致人身伤亡和财产损失等事故的易燃易爆物品。
        危险品具体品类
      </p>
      <p class="paragraph-content">
        枪支弹药警械类：武器及其部件、各种类型军用枪支、民用枪支、运动枪等及其他爆炸物品、纵火器材等。
        管制刀具类：匕首、三棱刀、弹簧刀等类似物品。
        毒品类：鸦片、吗啡、大麻、海洛因等众多毒品相关物品。
        麻醉药品类：阿片粉、盐酸吗啡注射液等多种麻醉药品。
        高价值类：所有国家货币（现金等）、有价证券、黄金、白银等珍贵物品。
        禁运读物及音像制品：对中国政治、经济等有害，涉及国家机密，违反
        “一个中国” 原则，淫秽、迷信、盗版类等相关印刷品及器具、音像制品。
        烟草及动植物皮毛类：烟草（10 条以上含 10
        条）、尸骨、未经硝制或药制的兽皮兽骨等。
        易燃易爆物品：酒精、汽油、干冰、鞭炮等众多易产生危险化学反应的物品。
        化学危险品：氧化剂、硫酸、硝酸、农药（中性毒及以上）等多种化学相关的危险物品以及带有活病原体的物质等。
        放射性物品类：具有 α、β、γ
        射线和中子流的货物及金属，如镭、铀等核能物品。
        含特定标志货物：外包装或货物本身含有相关禁运标志的货物，以及《内河禁运危险化学品目录（2015
        版）》（试行）中列明的危险化学品。 高风险类及无法界定价值货物包含
      </p>
      <p class="paragraph-content">
        无法界定价值货物：如无法界定价值的工艺品、珍稀木料家具等。
        易腐败变质货物：易腐败变质的鲜活食品、食材。
        无防护包装易碎品：无防护包装的易碎品等。
      </p>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button class="buttons" size="small" type="primary" @click="confirm"
        >我已确认</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: true,
    };
  },
  methods: {
    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>
<style scoped lang="scss">
.paragraph-content {
  line-height: 2;
}
</style>
