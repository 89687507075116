<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <el-row style="margin-top: 50px">
        <el-col :span="23">
          <el-form
            ref="addFormRef"
            :model="addForm"
            :rules="rules"
            size="small"
            label-width="120px"
          >
            <div>
              <el-row>
                <div style="line-height: 35px; margin-left: 35px">发布信息</div>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="发货公司" prop="sendOwnerId">
                    <el-select
                      @change="setSendAddress"
                      v-model="addForm.sendOwnerId"
                      filterable
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in ownerOptions"
                        :key="item.id"
                        :value="item.id"
                        :label="item.name"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="联系人" prop="sendContacts">
                    <el-select
                      v-model="addForm.sendContacts"
                      @change="setSendPhone"
                      style="width: 100%"
                      filterable
                    >
                      <el-option
                        v-for="item in contactsOptions"
                        :key="item.name"
                        :value="item.name"
                        :label="item.name"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="联系电话" prop="sendPhone">
                    <el-input v-model="addForm.sendPhone"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="发货地址" prop="sendAddrCode">
                    <areaSelect v-model="addForm.sendAddrCode"></areaSelect>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="详细地址" prop="sendAddressDetail">
                    <el-input v-model="addForm.sendAddressDetail"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div>
              <el-row>
                <div style="line-height: 35px; margin-left: 35px">收货信息</div>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="收货公司" prop="receiveOwnerId">
                    <el-select
                      @change="setReceiveAddress"
                      v-model="addForm.receiveOwnerId"
                      filterable
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in ownerOptions"
                        :key="item.id"
                        :value="item.id"
                        :label="item.name"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="联系人" prop="receiveContacts">
                    <el-select
                      v-model="addForm.receiveContacts"
                      @change="setReceivePhone"
                      style="width: 100%"
                      filterable
                    >
                      <el-option
                        v-for="item in contactsOptions"
                        :key="item.name"
                        :value="item.name"
                        :label="item.name"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="联系电话" prop="receivePhone">
                    <el-input v-model="addForm.receivePhone"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="收货地址" prop="receiveAddrCode">
                    <areaSelect v-model="addForm.receiveAddrCode"></areaSelect>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="详细地址" prop="receiveAddressDetail">
                    <el-input v-model="addForm.receiveAddressDetail"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div>
              <el-row>
                <div style="line-height: 35px; margin-left: 35px">运输信息</div>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="车辆类型" prop="truckType">
                    <el-select
                      v-model="addForm.truckType"
                      filterable
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in truckTypeOptions"
                        :key="item.val"
                        :value="item.val"
                        :label="item.val"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="装货日期" prop="loadingDate">
                    <el-date-picker
                      v-model="addForm.loadingDate"
                      type="date"
                      value-format="yyyy-MM-dd"
                      style="width: 100%"
                      filterable
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="装车费(元/车)" prop="loadingCharge">
                    <el-input v-model="addForm.loadingCharge"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="卸车费(元/车)" prop="unloadingCharge">
                    <el-input v-model="addForm.unloadingCharge"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="16">
                  <el-form-item label="装卸要求" prop="loadRequirement">
                    <el-input
                      type="textarea"
                      v-model="addForm.loadRequirement"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div>
              <el-row>
                <div style="line-height: 35px; margin-left: 35px">货物信息</div>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="货类" prop="cargoTypeId">
                    <treeSelect
                      :selectValue="addForm.cargoTypeId"
                      v-model="addForm.cargoTypeId"
                      @send="handleCargoTypeChange"
                    ></treeSelect>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="货名" prop="cargoTypeNameId">
                    <el-select v-model="addForm.cargoTypeNameId">
                      <el-option
                        v-for="item in cargoTypeNameOptions"
                        :key="item.id"
                        :label="item.cargoName"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="货物重量" prop="plantons">
                    <el-input v-model="addForm.plantons"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="运费单价(元/吨)" prop="freightCharge">
                    <el-input v-model="addForm.freightCharge"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="委托人" prop="cargoAgent">
                    <el-select
                      v-model="addForm.cargoAgent"
                      @change="setAgentPhone"
                      style="width: 100%"
                      filterable
                    >
                      <el-option
                        v-for="item in contactsOptions"
                        :key="item.name"
                        :value="item.name"
                        :label="item.name"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="委托人电话" prop="cargoAgentPhone">
                    <el-input v-model="addForm.cargoAgentPhone"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <el-row>
              <el-col :span="6">
                <el-form-item label="发布去向">
                  <el-radio-group v-model="addForm.isopen">
                    <el-radio :label="0">货源大厅</el-radio>
                    <el-radio :label="1">车队/司机</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                  <el-checkbox v-model="addForm.accept"></el-checkbox>
                  我已阅读并知晓<el-button
                    size="large"
                    type="text"
                    @click="showAgreement = true"
                    >《服务协议》</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
      <el-row style="margin-top: 50px">
        <el-col :offset="9">
          <template v-if="this.$route.query.id">
            <el-button
              size="small"
              class="buttons"
              type="primary"
              @click="confirmUpdate(10)"
              >保存</el-button
            >
            <el-button
              size="small"
              class="buttons"
              type="primary"
              @click="confirmUpdate(11)"
              >保存并发布</el-button
            >
          </template>
          <template v-else>
            <el-button
              size="small"
              class="buttons"
              type="primary"
              @click="confirmAdd(10)"
              >保存</el-button
            >
            <el-button
              size="small"
              class="buttons"
              type="primary"
              @click="confirmAdd(11)"
              >保存并发布</el-button
            >
          </template>
          <el-button size="small" class="buttons" @click="goBack"
            >取消</el-button
          >
        </el-col>
      </el-row>
      <Agreement
        v-if="showAgreement"
        ref="addCargoNameRef"
        @confirm="agreeConfirm"
        @cancel="showAgreement = false"
      ></Agreement>
    </div>
  </div>
</template>
<script>
import areaSelect from "@/components/areaSelect.vue";
import treeSelect from "@/components/treeSelect.vue";
import { codeToText } from "element-china-area-data";
import Agreement from "./agreement";
export default {
  name: "particulars",
  data() {
    return {
      addForm: {
        sendOwnerId: "",
        sendContacts: "",
        sendPhone: "",
        sendAddrCode: [],
        sendProvince: "",
        sendCity: "",
        sendAddressDetail: "",
        receiveOwnerId: "",
        receiveContacts: "",
        receivePhone: "",
        receiveAddrCode: [],
        receiveProvince: "",
        receiveCity: "",
        receiveAddressDetail: "",
        truckType: "",
        loadingDate: "",
        loadingCharge: "",
        unloadingCharge: "",
        loadRequirement: "",
        cargoTypeId: "",
        cargoTypeNameId: "",
        plantons: "",
        freightCharge: "",
        cargoAgent: "",
        cargoAgentPhone: "",
        isopen: 0,
      },
      ownerOptions: [],
      contactsOptions: [],
      dischargeOptions: [],
      truckTypeOptions: [],
      cargoTypeNameOptions: [],
      showAgreement: false,
      rules: {
        sendOwnerId: [
          {
            required: true,
            message: "请选择发货公司",
            trigger: ["blur", "change"],
          },
        ],
        addContacts: [
          {
            required: true,
            message: "请选择发货联系人",
            trigger: ["blur", "change"],
          },
        ],
        sendPhone: [
          {
            required: true,
            message: "请输入发货人手机号",
            trigger: ["blur", "change"],
          },
        ],
        sendAddrCode: [
          {
            required: true,
            message: "请选择发货地址",
            trigger: ["blur", "change"],
          },
        ],
        sendAddressDetail: [
          {
            required: true,
            message: "请输入发货详细地址",
            trigger: ["blur", "change"],
          },
        ],
        receiveOwnerId: [
          {
            required: true,
            message: "请选择收货公司",
            trigger: ["blur", "change"],
          },
        ],
        receiveContacts: [
          {
            required: true,
            message: "请选择收货联系人",
            trigger: ["blur", "change"],
          },
        ],
        receivePhone: [
          {
            required: true,
            message: "请输入收货人手机号",
            trigger: ["blur", "change"],
          },
        ],
        receiveAddrCode: [
          {
            required: true,
            message: "请选择收货地址",
            trigger: ["blur", "change"],
          },
        ],
        receiveAddressDetail: [
          {
            required: true,
            message: "请输入收货详细地址",
            trigger: ["blur", "change"],
          },
        ],
        truckType: [
          {
            required: true,
            message: "请选择车型",
            trigger: ["blur", "change"],
          },
        ],
        cargoTypeId: [
          {
            required: true,
            message: "请选择货类",
            trigger: ["blur", "change"],
          },
        ],
        cargoTypeNameId: [
          {
            required: true,
            message: "请选择货名",
            trigger: ["blur", "change"],
          },
        ],
        plantons: [
          {
            required: true,
            message: "请输入装货吨数",
            trigger: ["blur", "change"],
          },
        ],
        loadingDate: [
          {
            required: true,
            message: "请选择装货日期",
            trigger: ["blur", "change"],
          },
        ],
        loadingCharge: [
          {
            required: true,
            message: "请输入装车费",
            trigger: ["blur", "change"],
          },
        ],
        freightCharge: [
          {
            required: true,
            message: "请输入运费",
            trigger: ["blur", "change"],
          },
        ],
        remark: [
          {
            required: true,
            message: "请输入备注",
            trigger: ["blur", "change"],
          },
        ],
        cargoAgent: [
          {
            required: true,
            message: "请选择委托人",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  components: {
    areaSelect,
    treeSelect,
    Agreement,
  },
  created() {
    this.getOwnerOptions();
    this.getContactsOptions();
    this.getTruckTypeOptions();

    if (this.$route.query.id) {
      this.$http(
        "api1",
        "/api/biz/PlanInfo/info/" + this.$route.query.id,
        "get"
      ).then((res) => {
        for (let key in this.addForm) {
          if (key == "sendAddrCode") {
            this.addForm[key] = res.data.data.sendAddrCodeStr.split(",");
          } else if (key == "receiveAddrCode") {
            this.addForm[key] = res.data.data.receiveAddrCodeStr.split(",");
          } else if (key == "cargoTypeNameId") {
            setTimeout(() => {
              this.addForm[key] = res.data.data.cargoTypeNameId.toString();
            }, 1);
          } else {
            this.addForm[key] = res.data.data[key];
          }
        }
      });
    }
  },
  mounted() {},
  methods: {
    async getOwnerOptions() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/EnterpriseInfo/list",
        "get",
        {}
      );
      this.ownerOptions = data.data.list;
    },
    async getContactsOptions() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/ContactsInfo/list",
        "get",
        {}
      );
      this.contactsOptions = data.data.list;
    },
    async getTruckTypeOptions() {
      const { data } = await this.$http("api1", "/sys/dic/list", "get", {
        dictionaryType: "车辆类型",
      });
      this.truckTypeOptions = data.data;
    },
    async handleCargoTypeChange(value) {
      this.addForm.cargoTypeNameId = "";
      if (value) {
        //this.addForm.cargoTypeId = value;
        const { data } = await this.$http(
          "api1",
          "/api/biz/CargoTypeInfo/cargoNameList",
          "get",
          {
            pid: this.addForm.cargoTypeId,
          }
        );
        this.cargoTypeNameOptions = data.data;
      } else {
        this.addForm.cargoTypeId = "";
      }
    },
    setSendPhone(val) {
      this.addForm.sendPhone = this.contactsOptions.find(
        (x) => x.name == val
      )?.phone;
    },
    setSendAddress(val) {
      let opt = this.ownerOptions.find((x) => x.id == val);
      console.log(opt);
      if (opt) {
        this.addForm.sendAddrCode = opt.addrCodeStr.split(",");
        this.addForm.sendAddressDetail = opt.addrDetail;
      }
    },
    setReceivePhone(val) {
      this.addForm.receivePhone = this.contactsOptions.find(
        (x) => x.name == val
      )?.phone;
    },

    setReceiveAddress(val) {
      let opt = this.ownerOptions.find((x) => x.id == val);
      console.log(opt);
      if (opt) {
        this.addForm.receiveAddrCode = opt.addrCodeStr.split(",");
        this.addForm.receiveAddressDetail = opt.addrDetail;
      }
    },
    setAgentPhone(val) {
      this.addForm.cargoAgentPhone = this.contactsOptions.find(
        (x) => x.name == val
      )?.phone;
    },
    async confirmAdd(status) {
      this.$refs.addFormRef.validate(async (valid) => {
        if (valid) {
          const { data } = await this.$http(
            "api1",
            "/api/biz/PlanInfo/save",
            "post",
            {
              ...this.addForm,
              sendProvince:
                this.addForm.sendAddrCode.length > 0
                  ? codeToText[this.addForm.sendAddrCode[0]]
                  : "",
              sendCity:
                this.addForm.sendAddrCode.length > 0
                  ? codeToText[this.addForm.sendAddrCode[1]]
                  : "",
              sendCounty:
                this.addForm.sendAddrCode.length > 0
                  ? codeToText[this.addForm.sendAddrCode[2]]
                  : "",
              sendAddrCodeStr: this.addForm.sendAddrCode.join(","),
              receiveProvince:
                this.addForm.receiveAddrCode.length > 0
                  ? codeToText[this.addForm.receiveAddrCode[0]]
                  : "",
              receiveCity:
                this.addForm.receiveAddrCode.length > 0
                  ? codeToText[this.addForm.receiveAddrCode[1]]
                  : "",
              receiveCounty:
                this.addForm.receiveAddrCode.length > 0
                  ? codeToText[this.addForm.receiveAddrCode[2]]
                  : "",
              receiveAddrCodeStr: this.addForm.receiveAddrCode.join(","),
              sendFlowCity: this.getSend(this.addForm),
              receiveFlowCity: this.getReceive(this.addForm),
              status: status,
            }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.goBack();
          }
        }
      });
    },
    async confirmUpdate(status) {
      this.$refs.addFormRef.validate(async (valid) => {
        if (valid) {
          const { data } = await this.$http(
            "api1",
            "/api/biz/PlanInfo/update",
            "post",
            {
              id: this.$route.query.id,
              ...this.addForm,
              sendProvince:
                this.addForm.sendAddrCode.length > 0
                  ? codeToText[this.addForm.sendAddrCode[0]]
                  : "",
              sendCity:
                this.addForm.sendAddrCode.length > 0
                  ? codeToText[this.addForm.sendAddrCode[1]]
                  : "",
              sendCounty:
                this.addForm.sendAddrCode.length > 0
                  ? codeToText[this.addForm.sendAddrCode[2]]
                  : "",
              sendAddrCodeStr: this.addForm.sendAddrCode.join(","),
              receiveProvince:
                this.addForm.receiveAddrCode.length > 0
                  ? codeToText[this.addForm.receiveAddrCode[0]]
                  : "",
              receiveCity:
                this.addForm.receiveAddrCode.length > 0
                  ? codeToText[this.addForm.receiveAddrCode[1]]
                  : "",
              receiveCounty:
                this.addForm.receiveAddrCode.length > 0
                  ? codeToText[this.addForm.receiveAddrCode[2]]
                  : "",
              receiveAddrCodeStr: this.addForm.receiveAddrCode.join(","),
              sendFlowCity: this.getSend(this.addForm),
              receiveFlowCity: this.getReceive(this.addForm),
              status: status,
            }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.goBack();
          }
        }
      });
    },
    getSend(item) {
      console.log(item);
      if (item.sendCity == "市辖区") {
        return item.sendProvince;
      } else if (item.sendCity == "省直辖县级行政区划") {
        return item.sendCounty;
      } else {
        return item.sendCity;
      }
    },
    getReceive(item) {
      console.log(item);
      if (item.receiveCity == "市辖区") {
        return item.receiveProvince;
      } else if (item.receiveCity == "省直辖县级行政区划") {
        return item.receiveCounty;
      } else {
        return item.receiveCity;
      }
    },
    agreeConfirm() {
      this.showAgreement = false;
      this.addForm.accept = true;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
